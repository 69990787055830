import React, { FC } from "react";
import { Helmet } from "react-helmet-async";

import {SignUp} from "@clerk/clerk-react";

export interface PageSignUpProps {
  className?: string;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Booking</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <div className="my-20 max-w-md mx-auto space-y-6 ">
          <SignUp signInUrl="/login" />
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
