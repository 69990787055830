import React from "react";
import {Helmet} from "react-helmet-async";

const TermsOfService = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5 pb-4">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Terms of service
          </h1>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p><strong>Effective Date</strong>: 05.11.2024</p>

        <p>Welcome to PuhkuseTark (puhkusetark.ee). By using our platform, you agree to the following terms. Please read
          them carefully.</p>

        <h2>1. Eligibility</h2>
        <p>Users must be at least 12 years old to create an account on PuhkuseTark.</p>

        <h2>2. Account Registration</h2>
        <ul>
          <li>To access most features, you need to create an account. Depending on your signup method, we may collect
            your email, phone number, and authenticate via Google or Facebook.
          </li>
          <li>You are responsible for keeping your login credentials secure and for any activity under your account.
          </li>
        </ul>

        <h2>3. Use of the Platform</h2>
        <ul>
          <li><strong>For Rental Management:</strong> The platform is designed for users to manage, participate in, or
            rent short-term properties.
          </li>
          <li><strong>User-Generated Content:</strong> Users can create public pages for their rentals, visible to other
            users. You agree that any content you post is accurate and lawful.
          </li>
        </ul>

        <h2>4. Payments</h2>
        <p>All payments are processed securely through Stripe. By using Stripe, you agree to their <a
          href="https://stripe.com/terms" target="_blank">terms of service</a> and privacy policy.</p>

        <h2>5. User Responsibilities</h2>
        <ul>
          <li><strong>Prohibited Conduct:</strong> Users may not misuse the platform for illegal activities, harassment,
            or spam.
          </li>
          <li><strong>Content Responsibility:</strong> You are responsible for any content you post and must ensure it
            does not infringe on the rights of others.
          </li>
        </ul>

        <h2>6. Intellectual Property</h2>
        <p>All content and features on PuhkuseTark are protected by intellectual property rights. You may not copy,
          distribute, or use content without permission, except as expressly allowed on the platform.</p>

        <h2>7. Location Data</h2>
        <p>The platform may access your location to improve services and provide location-based search
          functionality.</p>

        <h2>8. Disclaimer of Warranties</h2>
        <p>PuhkuseTark provides the platform "as is" and disclaims all warranties, express or implied.</p>

        <h2>9. Limitation of Liability</h2>
        <p>PuhkuseTark will not be liable for any indirect, incidental, or consequential damages arising from your use
          of the platform.</p>

        <h2>10. Changes to the Terms</h2>
        <p>We may modify these Terms of Use at any time. Continued use of the platform constitutes acceptance of any
          revised terms.</p>

        <h2>11. Contact Information</h2>
        <p>For any questions or issues related to these Terms of Use, please contact us at info@puhkusetark.ee.</p>

      </div>
    );
  };


  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>PuhkuseTark | Terms of service</title>
      </Helmet>
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
    </div>
  );
};

export default TermsOfService;
