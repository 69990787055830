import React from "react";
import ReactDOM from "react-dom/client";
import {APIProvider} from "@vis.gl/react-google-maps";
import {HelmetProvider} from "react-helmet-async";
//
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

//
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import {eeEE} from "./locale/clerk-locale.ee";

import {ClerkProvider} from '@clerk/clerk-react'
import SocketIoProvider from "./socket";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Clerk Publishable Key")
}

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}>
        <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/" localization={eeEE}>
          <SocketIoProvider>
            <App/>
          </SocketIoProvider>
        </ClerkProvider>
      </APIProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
