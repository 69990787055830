import type {LocalizationResource} from '@clerk/types';

export const eeEE: LocalizationResource = {
  locale: 'et-EE',
  __experimental_userVerification: {
    alternativeMethods: {
      actionLink: 'Abi saamiseks',
      actionText: 'Kas pole ühtegi neist?',
      blockButton__backupCode: 'Kasuta varukoodi',
      blockButton__emailCode: 'Saada e-kood aadressile {{identifier}}',
      blockButton__password: 'Jätka oma parooliga',
      blockButton__phoneCode: 'Saada SMS-kood numbrile {{identifier}}',
      blockButton__totp: 'Kasuta autentimisrakendust',
      getHelp: {
        blockButton__emailSupport: 'Saada e-kiri tugiteenusele',
        content:
          'Kui teil on raskusi konto kinnitamisega, saatke meile e-kiri ja me aitame teil juurdepääsu taastada nii kiiresti kui võimalik.',
        title: 'Saage abi',
      },
      subtitle: 'Probleeme? Võite kasutada mõnda neist meetoditest kinnitamiseks.',
      title: 'Kasuta teist meetodit',
    },
    backupCodeMfa: {
      subtitle: 'Teie varukood on see, mille saite kaheastmelise autentimise seadistamisel.',
      title: 'Sisestage varukood',
    },
    emailCode: {
      formTitle: 'Kinnituskood',
      resendButton: 'Kas koodi ei saanud? Saada uuesti',
      subtitle: 'et jätkata rakendusse {{applicationName}}',
      title: 'Kontrolli oma e-posti',
    },
    noAvailableMethods: {
      message: 'Kinnitamist ei saa jätkata. Autentimisvõimalusi pole saadaval.',
      subtitle: 'Tekkis viga',
      title: 'Konto kinnitamine ebaõnnestus',
    },
    password: {
      actionLink: 'Kasuta teist meetodit',
      subtitle: 'Sisestage oma kontoga seotud parool',
      title: 'Sisestage oma parool',
    },
    phoneCode: {
      formTitle: 'Kinnituskood',
      resendButton: 'Kas koodi ei saanud? Saada uuesti',
      subtitle: 'et jätkata rakendusse {{applicationName}}',
      title: 'Kontrolli oma telefoni',
    },
    phoneCodeMfa: {
      formTitle: 'Kinnituskood',
      resendButton: 'Kas koodi ei saanud? Saada uuesti',
      subtitle: 'Jätkamiseks sisestage kood, mis saadeti teie telefonile',
      title: 'Kontrolli oma telefoni',
    },
    totpMfa: {
      formTitle: 'Kinnituskood',
      subtitle: 'Jätkamiseks sisestage autentimisrakenduse genereeritud kood',
      title: 'Kaheastmeline kinnitamine',
    },
  },
  backButton: 'Tagasi',
  badge__default: 'Vaikimisi',
  badge__otherImpersonatorDevice: 'Teine teeskleja seade',
  badge__primary: 'Peamine',
  badge__requiresAction: 'Vajab tegevust',
  badge__thisDevice: 'See seade',
  badge__unverified: 'Kinnitamata',
  badge__userDevice: 'Kasutaja seade',
  badge__you: 'Sina',
  createOrganization: {
    formButtonSubmit: 'Loo organisatsioon',
    invitePage: {
      formButtonReset: 'Jäta vahele',
    },
    title: 'Loo organisatsioon',
  },
  dates: {
    lastDay: 'Eile kell {{ date | timeString("et-EE") }}',
    next6Days: '{{ date | weekday("et-EE", "long") }} kell {{ date | timeString("et-EE") }}',
    nextDay: 'Homme kell {{ date | timeString("et-EE") }}',
    numeric: '{{ date | numeric("et-EE") }}',
    previous6Days: 'Eelmine {{ date | weekday("et-EE", "long") }} kell {{ date | timeString("et-EE") }}',
    sameDay: 'Täna kell {{ date | timeString("et-EE") }}',
  },
  dividerText: 'või',
  footerActionLink__useAnotherMethod: 'Kasuta teist meetodit',
  footerPageLink__help: 'Abi',
  footerPageLink__privacy: 'Privaatsus',
  footerPageLink__terms: 'Tingimused',
  formButtonPrimary: 'Jätka',
  formButtonPrimary__verify: 'Kinnita',
  formFieldAction__forgotPassword: 'Unustasid parooli?',
  formFieldError__matchingPasswords: 'Paroolid kattuvad.',
  formFieldError__notMatchingPasswords: 'Paroolid ei kattu.',
  formFieldError__verificationLinkExpired: 'Kinnituslink on aegunud. Palun taotle uus link.',
  formFieldHintText__optional: 'Valikuline',
  formFieldHintText__slug: 'Slugg on inimloetav ID, mis peab olema unikaalne. Seda kasutatakse sageli URL-ides.',
  formFieldInputPlaceholder__backupCode: 'Varukood',
  formFieldInputPlaceholder__confirmDeletionUserAccount: 'Kustuta konto',
  formFieldInputPlaceholder__emailAddress: 'e-posti aadress',
  formFieldInputPlaceholder__emailAddress_username: 'e-posti aadress või kasutajanimi',
  formFieldInputPlaceholder__emailAddresses: 'naidis@email.ee, naidis2@email.com',
  formFieldInputPlaceholder__firstName: 'Eesnimi',
  formFieldInputPlaceholder__lastName: 'Perekonnanimi',
  formFieldInputPlaceholder__organizationDomain: 'Organisatsiooni domeen',
  formFieldInputPlaceholder__organizationDomainEmailAddress: 'Organisatsiooni e-posti aadress',
  formFieldInputPlaceholder__organizationName: 'Organisatsiooni nimi',
  formFieldInputPlaceholder__organizationSlug: 'minu-org',
  formFieldInputPlaceholder__password: 'Parool',
  formFieldInputPlaceholder__phoneNumber: 'Telefoninumber',
  formFieldInputPlaceholder__username: 'Kasutajanimi',
  formFieldLabel__automaticInvitations: 'Luba automaatsed kutsed sellele domeenile',
  formFieldLabel__backupCode: 'Varukood',
  formFieldLabel__confirmDeletion: 'Kinnitamine',
  formFieldLabel__confirmPassword: 'Kinnita parool',
  formFieldLabel__currentPassword: 'Praegune parool',
  formFieldLabel__emailAddress: 'E-posti aadress',
  formFieldLabel__emailAddress_username: 'E-posti aadress või kasutajanimi',
  formFieldLabel__emailAddresses: 'E-posti aadressid',
  formFieldLabel__firstName: 'Eesnimi',
  formFieldLabel__lastName: 'Perekonnanimi',
  formFieldLabel__newPassword: 'Uus parool',
  formFieldLabel__organizationDomain: 'Domeen',
  formFieldLabel__organizationDomainDeletePending: 'Kustuta ootel kutsed ja soovitused',
  formFieldLabel__organizationDomainEmailAddress: 'Kinnituse e-posti aadress',
  formFieldLabel__organizationDomainEmailAddressDescription: 'Sisestage selle domeeni all e-posti aadress koodi saamiseks ja domeeni kinnitamiseks.',
  formFieldLabel__organizationName: 'Nimi',
  formFieldLabel__organizationSlug: 'Slug',
  formFieldLabel__passkeyName: 'Paroolivõtme nimi',
  formFieldLabel__password: 'Parool',
  formFieldLabel__phoneNumber: 'Telefoninumber',
  formFieldLabel__role: 'Roll',
  formFieldLabel__signOutOfOtherSessions: 'Logi välja kõikidest teistest seadmetest',
  formFieldLabel__username: 'Kasutajanimi',
  impersonationFab: {
    action__signOut: 'Logi välja',
    title: 'Sisselogitud kui {{identifier}}',
  },
  maintenanceMode: "Meil on praegu hooldustööd, kuid ärge muretsege, see ei tohiks võtta rohkem kui paar minutit.",
  membershipRole__admin: 'Administraator',
  membershipRole__basicMember: 'Liige',
  membershipRole__guestMember: 'Külastaja',
  organizationList: {
    action__createOrganization: 'Loo organisatsioon',
    action__invitationAccept: 'Liitu',
    action__suggestionsAccept: 'Küsi liitumist',
    createOrganization: 'Loo organisatsioon',
    invitationAcceptedLabel: 'Liitunud',
    subtitle: 'et jätkata rakendusse {{applicationName}}',
    suggestionsAcceptedLabel: 'Ootel kinnitamine',
    title: 'Vali konto',
    titleWithoutPersonal: 'Vali organisatsioon',
  },
  organizationProfile: {
    badge__automaticInvitation: 'Automaatsed kutsed',
    badge__automaticSuggestion: 'Automaatsed soovitused',
    badge__manualInvitation: 'Ei automaatset registreerimist',
    badge__unverified: 'Kinnitamata',
    createDomainPage: {
      subtitle: 'Lisa domeen kinnitamiseks. Selle domeeni alla kuuluvate e-posti aadressidega kasutajad saavad organisatsiooniga automaatselt liituda või küsida liitumist.',
      title: 'Lisa domeen',
    },
    invitePage: {
      detailsTitle__inviteFailed: 'Kutseid ei saanud saata. Järgmiste e-posti aadresside jaoks on juba ootel kutsed: {{email_addresses}}.',
      formButtonPrimary__continue: 'Saada kutseid',
      selectDropdown__role: 'Vali roll',
      subtitle: 'Sisestage või kleepige üks või mitu e-posti aadressi, eraldatud tühikute või koma abil.',
      successMessage: 'Kutsed on edukalt saadetud',
      title: 'Kutsu uusi liikmeid',
    },
    membersPage: {
      action__invite: 'Kutsu',
      activeMembersTab: {
        menuAction__remove: 'Eemalda liige',
        tableHeader__actions: 'Tegevused',
        tableHeader__joined: 'Liitunud',
        tableHeader__role: 'Roll',
        tableHeader__user: 'Kasutaja',
      },
      detailsTitle__emptyRow: 'Kuvamiseks pole liikmeid',
      invitationsTab: {
        autoInvitations: {
          headerSubtitle: 'Kutsu kasutajaid, ühendades e-posti domeeni oma organisatsiooniga. Igaüks, kes registreerib vastava e-posti domeeni, saab igal ajal organisatsiooniga liituda.',
          headerTitle: 'Automaatsed kutsed',
          primaryButton: 'Halda kinnitatud domeene',
        },
        table__emptyRow: 'Kuvamiseks pole kutseid',
      },
      invitedMembersTab: {
        menuAction__revoke: 'Tühista kutse',
        tableHeader__invited: 'Kutsutud',
      },
      requestsTab: {
        autoSuggestions: {
          headerSubtitle: 'Kasutajad, kes registreerivad vastava e-posti domeeni, saavad näha soovitust küsida liitumist teie organisatsiooniga.',
          headerTitle: 'Automaatsed soovitused',
          primaryButton: 'Halda kinnitatud domeene',
        },
        menuAction__approve: 'Kinnita',
        menuAction__reject: 'Lükka tagasi',
        tableHeader__requested: 'Küsimus juurdepääsu saamiseks',
        table__emptyRow: 'Kuvamiseks pole küsimusi',
      },
      start: {
        headerTitle__invitations: 'Kutsed',
        headerTitle__members: 'Liikmed',
        headerTitle__requests: 'Küsimused',
      },
    },
    navbar: {
      description: 'Halda oma organisatsiooni.',
      general: 'Üldine',
      members: 'Liikmed',
      title: 'Organisatsioon',
    },
    profilePage: {
      dangerSection: {
        deleteOrganization: {
          actionDescription: 'Keri alla ja sisesta "{{organizationName}}" jätkamiseks.',
          messageLine1: 'Kas oled kindel, et soovid selle organisatsiooni kustutada?',
          messageLine2: 'See toiming on püsiv ja pöördumatu.',
          successMessage: 'Oled organisatsiooni kustutanud.',
          title: 'Kustuta organisatsioon',
        },
        leaveOrganization: {
          actionDescription: 'Keri alla ja sisesta "{{organizationName}}" jätkamiseks.',
          messageLine1:
            'Kas oled kindel, et soovid sellest organisatsioonist lahkuda? Kaotad juurdepääsu sellele organisatsioonile ja selle rakendustele.',
          messageLine2: 'See toiming on püsiv ja pöördumatu.',
          successMessage: 'Oled organisatsioonist lahkunud.',
          title: 'Lahku organisatsioonist',
        },
        title: 'Oht',
      },
      domainSection: {
        menuAction__manage: 'Halda',
        menuAction__remove: 'Kustuta',
        menuAction__verify: 'Kinnita',
        primaryButton: 'Lisa domeen',
        subtitle:
          'Luba kasutajatel automaatselt organisatsiooni liituda või taotleda liitumist kinnitatud e-posti domeeni alusel.',
        title: 'Kinnitatud domeenid',
      },
      successMessage: 'Organisatsioon on uuendatud.',
      title: 'Uuenda profiili',
    },
    removeDomainPage: {
      messageLine1: 'E-posti domeen {{domain}} eemaldatakse.',
      messageLine2: 'Kasutajad ei saa pärast seda organisatsiooniga automaatselt liituda.',
      successMessage: '{{domain}} on eemaldatud.',
      title: 'Eemalda domeen',
    },
    start: {
      headerTitle__general: 'Üldine',
      headerTitle__members: 'Liikmed',
      profileSection: {
        primaryButton: 'Uuenda profiili',
        title: 'Organisatsiooni profiil',
        uploadAction__title: 'Logo',
      },
    },
    verifiedDomainPage: {
      dangerTab: {
        calloutInfoLabel: 'Selle domeeni eemaldamine mõjutab kutsetega kasutajaid.',
        removeDomainActionLabel__remove: 'Eemalda domeen',
        removeDomainSubtitle: 'Eemalda see domeen oma kinnitatud domeenidest',
        removeDomainTitle: 'Eemalda domeen',
      },
      enrollmentTab: {
        automaticInvitationOption__description:
          'Kasutajad kutsutakse automaatselt organisatsiooni, kui nad registreeruvad, ja nad saavad igal ajal liituda.',
        automaticInvitationOption__label: 'Automaatsed kutsed',
        automaticSuggestionOption__description:
          'Kasutajatele antakse soovitus taotleda liitumist, kuid nad peavad enne organisatsiooni liitumiseks administraatori heakskiitu saama.',
        automaticSuggestionOption__label: 'Automaatsed soovitused',
        calloutInfoLabel: 'Liitumismooduse muutmine mõjutab ainult uusi kasutajaid.',
        calloutInvitationCountLabel: 'Ootel kutseid kasutajatele: {{count}}',
        calloutSuggestionCountLabel: 'Ootel soovitusi kasutajatele: {{count}}',
        manualInvitationOption__description: 'Kasutajaid saab organisatsiooni kutsuda ainult käsitsi.',
        manualInvitationOption__label: 'Ei mingit automaatset liitumist',
        subtitle: 'Vali, kuidas saavad selle domeeni kasutajad organisatsiooniga liituda.',
      },
      start: {
        headerTitle__danger: 'Oht',
        headerTitle__enrollment: 'Liitumisvõimalused',
      },
      subtitle: 'Domeen {{domain}} on nüüd kinnitatud. Jätka, valides liitumismooduse.',
      title: 'Uuenda {{domain}}',
    },
    verifyDomainPage: {
      formSubtitle: 'Sisesta verifitseerimiskood, mis saadeti sinu e-posti aadressile',
      formTitle: 'Verifitseerimiskood',
      resendButton: 'Ei saanud koodi? Saada uuesti',
      subtitle: 'Domeen {{domainName}} tuleb kinnitada e-posti teel.',
      subtitleVerificationCodeScreen: 'Verifitseerimiskood saadeti aadressile {{emailAddress}}. Sisesta kood, et jätkata.',
      title: 'Kinnita domeen',
    },
  },
  organizationSwitcher: {
    action__createOrganization: 'Loo organisatsioon',
    action__invitationAccept: 'Liitu',
    action__manageOrganization: 'Halda',
    action__suggestionsAccept: 'Küsi liitumist',
    notSelected: 'Organisatsiooni pole valitud',
    personalWorkspace: 'Isiklik konto',
    suggestionsAcceptedLabel: 'Ootel kinnitamine',
  },
  paginationButton__next: 'Järgmine',
  paginationButton__previous: 'Eelmine',
  paginationRowText__displaying: 'Kuvamine',
  paginationRowText__of: 'kokku',
  signIn: {
    accountSwitcher: {
      action__addAccount: 'Lisa konto',
      action__signOutAll: 'Logi välja kõikidest kontodest',
      subtitle: 'Valige konto, millega soovite jätkata.',
      title: 'Valige konto',
    },
    alternativeMethods: {
      actionLink: 'Abi saamiseks',
      actionText: 'Kas pole ühtegi neist?',
      blockButton__backupCode: 'Kasuta varukoodi',
      blockButton__emailCode: 'Saada e-kood aadressile {{identifier}}',
      blockButton__emailLink: 'Saada e-kiri aadressile {{identifier}}',
      blockButton__passkey: 'Logi sisse oma paroolivõtmega',
      blockButton__password: 'Logi sisse oma parooliga',
      blockButton__phoneCode: 'Saada SMS-kood aadressile {{identifier}}',
      blockButton__totp: 'Kasuta autentimisrakendust',
      getHelp: {
        blockButton__emailSupport: 'Saada e-kiri tugiteenusele',
        content:
          'Kui teil on raskusi sisselogimisega oma kontole, saatke meile e-kiri ja me aitame teil juurdepääsu taastada nii kiiresti kui võimalik.',
        title: 'Saage abi',
      },
      subtitle: 'Probleeme? Võite kasutada mõnda neist meetoditest sisselogimiseks.',
      title: 'Kasuta teist meetodit',
    },
    backupCodeMfa: {
      subtitle: 'Teie varukood on see, mille saite kaheastmelise autentimise seadistamisel.',
      title: 'Sisestage varukood',
    },
    emailCode: {
      formTitle: 'Kinnituskood',
      resendButton: 'Kas koodi ei saanud? Saada uuesti',
      subtitle: 'et jätkata rakendusse {{applicationName}}',
      title: 'Kontrolli oma e-posti',
    },
    emailLink: {
      clientMismatch: {
        subtitle:
          'Jätkamiseks avage kinnituse link seadmes ja brauseris, kust alustasite sisselogimist',
        title: 'Kinnituslink ei sobi sellele seadmele',
      },
      expired: {
        subtitle: 'Tagasi originaaltabule, et jätkata.',
        title: 'See kinnituslink on aegunud',
      },
      failed: {
        subtitle: 'Tagasi originaaltabule, et jätkata.',
        title: 'See kinnituslink ei kehti',
      },
      formSubtitle: 'Kasuta e-kirjas saadetud kinnituse linki',
      formTitle: 'Kinnituslink',
      loading: {
        subtitle: 'Teid suunatakse varsti',
        title: 'Sisselogimine...',
      },
      resendButton: 'Kas linki ei saanud? Saada uuesti',
      subtitle: 'et jätkata rakendusse {{applicationName}}',
      title: 'Kontrolli oma e-posti',
      unusedTab: {
        title: 'Selle vahekaardi võite sulgeda',
      },
      verified: {
        subtitle: 'Teid suunatakse varsti',
        title: 'Edukalt sisse logitud',
      },
      verifiedSwitchTab: {
        subtitle: 'Tagasi originaaltabule, et jätkata',
        subtitleNewTab: 'Tagasi just avatud tabule, et jätkata',
        titleNewTab: 'Sisse logitud teises vahekaardis',
      },
    },
    forgotPassword: {
      formTitle: 'Parooli lähtestamise kood',
      resendButton: 'Kas koodi ei saanud? Saada uuesti',
      subtitle: 'et lähtestada oma parool',
      subtitle_email: 'Esiteks, sisestage e-kirjas saadetud kood',
      subtitle_phone: 'Esiteks, sisestage telefonile saadetud kood',
      title: 'Lähtestage parool',
    },
    forgotPasswordAlternativeMethods: {
      blockButton__resetPassword: 'Lähtesta oma parool',
      label__alternativeMethods: 'Või logi sisse teise meetodiga',
      title: 'Unustasite parooli?',
    },
    noAvailableMethods: {
      message: 'Sisselogimist ei saa jätkata. Autentimisvõimalusi pole saadaval.',
      subtitle: 'Tekkis viga',
      title: 'Sisselogimine ebaõnnestus',
    },
    passkey: {
      subtitle: 'Paroolivõtme kasutamine kinnitab, et olete see, kelleks end peate. Teie seade võib küsida sõrmejälge, näotuvastust või ekraani lukku.',
      title: 'Kasuta oma paroolivõtme',
    },
    password: {
      actionLink: 'Kasuta teist meetodit',
      subtitle: 'Sisestage oma kontoga seotud parool',
      title: 'Sisestage oma parool',
    },
    passwordPwned: {
      title: 'Parool on ohustatud',
    },
    phoneCode: {
      formTitle: 'Kinnituskood',
      resendButton: 'Kas koodi ei saanud? Saada uuesti',
      subtitle: 'et jätkata rakendusse {{applicationName}}',
      title: 'Kontrolli oma telefoni',
    },
    phoneCodeMfa: {
      formTitle: 'Kinnituskood',
      resendButton: 'Kas koodi ei saanud? Saada uuesti',
      subtitle: 'Jätkamiseks sisestage kood, mis saadeti teie telefonile',
      title: 'Kontrolli oma telefoni',
    },
    resetPassword: {
      formButtonPrimary: 'Lähtesta parool',
      requiredMessage: 'Turvakaalutlustel on vajalik oma parooli lähtestamine.',
      successMessage: 'Teie parool on edukalt muudetud. Sisselogimiseks palun oodake hetk.',
      title: 'Seadke uus parool',
    },
    resetPasswordMfa: {
      detailsLabel: 'Peame teie identiteeti kinnitama enne parooli lähtestamist.',
    },
    start: {
      actionLink: 'Registreeru',
      actionLink__use_email: 'Kasutage e-kirja',
      actionLink__use_email_username: 'Kasutage e-kirja või kasutajanime',
      actionLink__use_passkey: 'Kasutage paroolivõtit',
      actionLink__use_phone: 'Kasutage telefoni',
      actionLink__use_username: 'Kasutage kasutajanime',
      actionText: 'Kas teil pole kontot?',
      subtitle: 'Tere tulemast tagasi! Palun logige sisse, et jätkata',
      title: 'Logi sisse rakendusse {{applicationName}}',
    },
    totpMfa: {
      formTitle: 'Kinnituskood',
      subtitle: 'Jätkamiseks sisestage autentimisrakenduse genereeritud kood',
      title: 'Kaheastmeline kinnitamine',
    },
  },

  signInEnterPasswordTitle: 'Sisestage oma parool',
  signUp: {
    continue: {
      actionLink: 'Logi sisse',
      actionText: 'Kas teil on juba konto?',
      subtitle: 'Palun täitke puuduolevad andmed, et jätkata.',
      title: 'Täiendage puuduvad väljad',
    },
    emailCode: {
      formSubtitle: 'Sisestage e-kirjas saadetud kinnituskood',
      formTitle: 'Kinnituskood',
      resendButton: 'Kas koodi ei saanud? Saada uuesti',
      subtitle: 'Sisestage e-kirjas saadetud kinnituskood',
      title: 'Kinnitage oma e-post',
    },
    emailLink: {
      clientMismatch: {
        subtitle:
          'Jätkamiseks avage kinnituse link seadmes ja brauseris, kust alustasite registreerimist',
        title: 'Kinnituslink ei sobi sellele seadmele',
      },
      formSubtitle: 'Kasuta e-kirjas saadetud kinnituse linki',
      formTitle: 'Kinnituslink',
      loading: {
        title: 'Registreerimine...',
      },
      resendButton: 'Kas linki ei saanud? Saada uuesti',
      subtitle: 'et jätkata rakendusse {{applicationName}}',
      title: 'Kinnitage oma e-post',
      verified: {
        title: 'Edukalt registreeritud',
      },
      verifiedSwitchTab: {
        subtitle: 'Tagasi just avatud tabule, et jätkata',
        subtitleNewTab: 'Tagasi originaaltabule, et jätkata',
        title: 'Edukalt kinnitatud e-post',
      },
    },
    phoneCode: {
      formSubtitle: 'Sisestage telefoninumbrile saadetud kinnituskood',
      formTitle: 'Kinnituskood',
      resendButton: 'Kas koodi ei saanud? Saada uuesti',
      subtitle: 'Sisestage telefonile saadetud kinnituskood',
      title: 'Kinnitage oma telefon',
    },
    start: {
      actionLink: 'Logi sisse',
      actionLink__use_email: 'Kasutage e-kirja',
      actionLink__use_phone: 'Kasutage telefoni',
      actionText: 'Kas teil on juba konto?',
      subtitle: 'Tere tulemast! Palun täitke andmed, et alustada.',
      title: 'Loo oma konto',
    },
    restrictedAccess: {
      title: 'Piiratud juurdepääs',
      subtitle: 'Sellele rakendusele on juurdepääs piiratud ja registreerimiseks on vajalik kutse.',
      actionLink: 'Tagasi sisselogimise juurde',
    },
  },
  socialButtonsBlockButton: 'Jätka {{provider|titleize}}',
  socialButtonsBlockButtonManyInView: '{{provider|titleize}}',
  unstable__errors: {
    already_a_member_in_organization: '{{email}} on juba organisatsiooni liige.',
    captcha_invalid:
      'Registreerimine ebaõnnestus, kuna turvalisuse kontrollid ebaõnnestusid. Palun värskendage lehte, et proovida uuesti, või pöörduge toe poole täiendava abi saamiseks.',
    captcha_unavailable:
      'Registreerimine ebaõnnestus, kuna roboti valideerimine ebaõnnestus. Palun värskendage lehte, et proovida uuesti, või pöörduge toe poole täiendava abi saamiseks.',
    form_code_incorrect: 'Kood on vale.',
    form_identifier_exists: 'See identifikaator juba eksisteerib.',
    form_identifier_exists__email_address: 'See e-posti aadress on juba kasutusel. Palun proovige teist.',
    form_identifier_exists__phone_number: 'See telefoninumber on juba kasutusel. Palun proovige teist.',
    form_identifier_exists__username: 'See kasutajanimi on juba kasutusel. Palun proovige teist.',
    form_identifier_not_found: 'Identifikaatorit ei leitud.',
    form_param_format_invalid: 'Parameeter ei ole kehtivas formaadis.',
    form_param_format_invalid__email_address: 'E-posti aadress peab olema kehtiv e-posti aadress.',
    form_param_format_invalid__phone_number: 'Telefoninumber peab olema kehtivas rahvusvahelises formaadis.',
    form_param_max_length_exceeded__first_name: 'Eesnimi ei tohi ületada 256 tähemärki.',
    form_param_max_length_exceeded__last_name: 'Perekonnanimi ei tohi ületada 256 tähemärki.',
    form_param_max_length_exceeded__name: 'Nimi ei tohi ületada 256 tähemärki.',
    form_param_nil: 'Parameeter ei tohi olla tühi.',
    form_param_value_invalid: 'Parameetri väärtus on vale.',
    form_password_incorrect: 'Vale parool.',
    form_password_length_too_short: 'Parool on liiga lühike.',
    form_password_not_strong_enough: 'Teie parool ei ole piisavalt tugev.',
    form_password_pwned:
      'See parool on leitud andmelekke osana ja seda ei saa kasutada. Palun proovige teist parooli.',
    form_password_pwned__sign_in:
      'See parool on leitud andmelekke osana ja seda ei saa kasutada. Palun lähtestage oma parool.',
    form_password_size_in_bytes_exceeded:
      'Teie parool on ületanud lubatud maksimaalse baitide arvu. Palun lühendage seda või eemaldage mõned erimärgid.',
    form_password_validation_failed: 'Vale parool.',
    form_username_invalid_character: 'Kasutajanimi sisaldab kehtetuks loetud märke.',
    form_username_invalid_length: 'Kasutajanimi peab olema teatud pikkusega.',
    identification_deletion_failed: 'Te ei saa oma viimast tuvastamist kustutada.',
    not_allowed_access: 'Juhtimine ei ole lubatud.',
    organization_domain_blocked: 'See on blokeeritud e-posti teenuse domeen. Palun kasutage muud.',
    organization_domain_common: 'See on levinud e-posti teenuse domeen. Palun kasutage muud.',
    organization_membership_quota_exceeded:
      'Olete saavutanud oma organisatsiooni liikmete piirangud, sealhulgas ootel kutsed.',
    organization_minimum_permissions_needed:
      'Organisatsioonis peab olema vähemalt üks liige, kellel on minimaalne vajalik õiguste tase.',
    passkey_already_exists: 'Selle seadme jaoks on juba registreeritud paroolivõti.',
    passkey_not_supported: 'Paroolivõtmed ei ole selle seadme jaoks toetatud.',
    passkey_pa_not_supported: 'Registreerimine nõuab platvormi autentijat, kuid seade ei toeta seda.',
    passkey_registration_cancelled: 'Paroolivõtme registreerimine tühistati või aegus.',
    passkey_retrieval_cancelled: 'Paroolivõtme kinnitamine tühistati või aegus.',
    passwordComplexity: {
      maximumLength: 'vähem kui {{length}} tähemärki',
      minimumLength: '{{length}} või enam tähemärki',
      requireLowercase: 'väike täht',
      requireNumbers: 'number',
      requireSpecialCharacter: 'erimärk',
      requireUppercase: 'suur täht',
      sentencePrefix: 'Teie parool peab sisaldama',
    },
    phone_number_exists: 'See telefoninumber on juba kasutusel. Palun proovige teist.',
    zxcvbn: {
      couldBeStronger: 'Teie parool töötab, kuid võiks olla tugevam. Proovige lisada rohkem tähemärke.',
      goodPassword: 'Teie parool vastab kõigile vajalikule.',
      notEnough: 'Teie parool ei ole piisavalt tugev.',
      suggestions: {
        allUppercase: 'Suurtähti on liiga palju, aga mitte kõik.',
        anotherWord: 'Lisage rohkem vähem levinud sõnu.',
        associatedYears: 'Vältige aastaid, mis on teie eluga seotud.',
        capitalization: 'Suurtähti rohkem kui ainult esimene.',
        dates: 'Vältige kuupäevi ja aastaid, mis on seotud teiega.',
        l33t: 'Vältige ettearvatavaid tähe asendusi, nagu "@" asemel "a".',
        longerKeyboardPattern: 'Kasutage pikemaid klaviatuuri mustreid ja muutke trükkimise suunda mitu korda.',
        noNeed: 'Te saate luua tugevaid paroole, kasutamata sümboleid, numbreid ega suuri tähti.',
        pwned: 'Kui kasutate seda parooli mujal, peaksite selle muutma.',
        recentYears: 'Vältige viimaseid aastaid.',
        repeated: 'Vältige korduvaid sõnu ja tähemärke.',
        reverseWords: 'Vältige tuntud sõnade tagurpidi kirjutamist.',
        sequences: 'Vältige levinud tähemärgi järjestusi.',
        useWords: 'Kasutage mitmeid sõnu, kuid vältige levinud fraase.',
      },
      warnings: {
        common: 'See on sageli kasutatav parool.',
        commonNames: 'Levinud nimed ja perekonnanimed on kergesti arvatavad.',
        dates: 'Kuupäevad on kergesti arvatavad.',
        extendedRepeat: 'Korduvad tähemärkimustrid, nagu "abcabcabc", on kergesti arvatavad.',
        keyPattern: 'Lühikesed klaviatuuri mustrid on kergesti arvatavad.',
        namesByThemselves: 'Üksikud nimed või perekonnanimed on kergesti arvatavad.',
        pwned: 'Teie parool on lekitatud andmelekke tõttu.',
        recentYears: 'Viimased aastad on kergesti arvatavad.',
        sequences: 'Levinud tähemärgi järjestused, nagu "abc", on kergesti arvatavad.',
        similarToCommon: 'See on sarnane levinud parooliga.',
        simpleRepeat: 'Korduvad tähemärgid, nagu "aaa", on kergesti arvatavad.',
        straightRow: 'Otse klaviatuuri ridade järjestused on kergesti arvatavad.',
        topHundred: 'See on tihti kasutatav parool.',
        topTen: 'See on väga kasutatav parool.',
        userInputs: 'Isikuandmeid ei tohi kasutada.',
        wordByItself: 'Üksikud sõnad on kergesti arvatavad.',
      },
    },
  },
  userButton: {
    action__addAccount: 'Lisa konto',
    action__manageAccount: 'Halda kontot',
    action__signOut: 'Logi välja',
    action__signOutAll: 'Logi välja kõikidest kontodest',
  },
  userProfile: {
    backupCodePage: {
      actionLabel__copied: 'Kopeeritud!',
      actionLabel__copy: 'Kopeeri kõik',
      actionLabel__download: 'Laadi .txt',
      actionLabel__print: 'Prindi',
      infoText1: 'Varukoode lubatakse selle konto jaoks.',
      infoText2: 'Hoia varukoode saladuses ja säilita neid turvaliselt. Kui kahtlustad, et need on ohustatud, saad uued varukoodid.',
      subtitle__codelist: 'Hoidke neid turvaliselt ja saladuses.',
      successMessage: 'Varukoodid on nüüd lubatud. Saate neid kasutada sisselogimiseks, kui kaotate juurdepääsu oma autentimisseadmest. Iga kood on ühekordselt kasutatav.',
      successSubtitle: 'Saate neid kasutada sisselogimiseks, kui kaotate juurdepääsu oma autentimisseadmest.',
      title: 'Lisa varukoodi kinnitamine',
      title__codelist: 'Varukoodid',
    },
    connectedAccountPage: {
      formHint: 'Valige pakkuja, et oma konto ühendada.',
      formHint__noAccounts: 'Ühendatud väliste kontode pakkujaid ei ole.',
      removeResource: {
        messageLine1: '{{identifier}} eemaldatakse sellest kontost.',
        messageLine2: 'Te ei saa enam seda ühendatud kontot kasutada ning kõik sõltuvad funktsioonid ei tööta.',
        successMessage: '{{connectedAccount}} on teie kontolt eemaldatud.',
        title: 'Eemalda ühendatud konto',
      },
      socialButtonsBlockButton: '{{provider|titleize}}',
      successMessage: 'Pakkuja on teie kontole lisatud',
      title: 'Lisa ühendatud konto',
    },
    deletePage: {
      actionDescription: 'Sisestage "Kustuta konto" allpool, et jätkata.',
      confirm: 'Kustuta konto',
      messageLine1: 'Kas olete kindel, et soovite oma konto kustutada?',
      messageLine2: 'See tegevus on püsiv ja pöördumatu.',
      title: 'Kustuta konto',
    },
    emailAddressPage: {
      emailCode: {
        formHint: 'E-kiri, milles on kinnituskood, saadetakse sellele e-posti aadressile.',
        formSubtitle: 'Sisestage kinnituskood, mis saadeti {{identifier}}',
        formTitle: 'Kinnituskood',
        resendButton: 'Kas koodi ei saanud? Saada uuesti',
        successMessage: 'E-posti aadress {{identifier}} on teie kontole lisatud.',
      },
      emailLink: {
        formHint: 'E-kiri, milles on kinnituse link, saadetakse sellele e-posti aadressile.',
        formSubtitle: 'Klõpsake kinnituse linki e-kirjas, mis saadeti {{identifier}}',
        formTitle: 'Kinnituslink',
        resendButton: 'Kas linki ei saanud? Saada uuesti',
        successMessage: 'E-posti aadress {{identifier}} on teie kontole lisatud.',
      },
      removeResource: {
        messageLine1: '{{identifier}} eemaldatakse sellest kontost.',
        messageLine2: 'Te ei saa enam selle e-posti aadressiga sisselogimist.',
        successMessage: '{{emailAddress}} on teie kontolt eemaldatud.',
        title: 'Eemalda e-posti aadress',
      },
      title: 'Lisa e-posti aadress',
      verifyTitle: 'Kinnita e-posti aadress',
    },
    formButtonPrimary__add: 'Lisa',
    formButtonPrimary__continue: 'Jätka',
    formButtonPrimary__finish: 'Lõpeta',
    formButtonPrimary__remove: 'Eemalda',
    formButtonPrimary__save: 'Salvesta',
    formButtonReset: 'Tühista',
    mfaPage: {
      formHint: 'Valige meetod, mida lisada.',
      title: 'Lisage kaheastmeline kinnitamine',
    },
    mfaPhoneCodePage: {
      backButton: 'Kasuta olemasolevat numbrit',
      primaryButton__addPhoneNumber: 'Lisa telefoninumber',
      removeResource: {
        messageLine1: '{{identifier}} ei saa enam sisselogimise ajal kinnituskoodide saamiseks.',
        messageLine2: 'Teie konto ei pruugi olla nii turvaline. Kas olete kindel, et soovite jätkata?',
        successMessage: 'SMS-koodi kaheastmeline kinnitamine on eemaldatud {{mfaPhoneCode}} jaoks',
        title: 'Eemalda kaheastmeline kinnitamine',
      },
      subtitle__availablePhoneNumbers:
        'Valige olemasolev telefoninumber, et registreerida SMS-koodi kaheastmeline kinnitamine või lisage uus.',
      subtitle__unavailablePhoneNumbers:
        'SMS-koodi kaheastmeline kinnitamine registreerimiseks pole saadaval telefoninumbreid, palun lisage uus.',
      successMessage1:
        'Sisselogimisel peate sisestama kinnituskoodi, mis saadetakse sellele telefoninumbrile lisasammuna.',
      successMessage2:
        'Salvesta need varukoodid ja hoia neid turvaliselt. Kui kaotate juurdepääsu oma autentimisseadmest, saate sisselogimiseks kasutada varukoode.',
      successTitle: 'SMS-koodi kinnitamine lubatud',
      title: 'Lisa SMS-koodi kinnitamine',
    },
    mfaTOTPPage: {
      authenticatorApp: {
        buttonAbleToScan__nonPrimary: 'Skaneeri QR-kood',
        buttonUnableToScan__nonPrimary: 'Ei saa QR-koodi skaneerida?',
        infoText__ableToScan:
          'Seadistage uus sisselogimisviis oma autentimisrakenduses ja skaneerige allolev QR-kood, et see oma kontoga siduda.',
        infoText__unableToScan: 'Seadistage uus sisselogimisviis oma autentimisrakenduses ja sisestage allolev võti.',
        inputLabel__unableToScan1:
          'Veenduge, et ajapõhised või ühekordsed paroolid on lubatud, ja lõpetage oma konto sidumine.',
        inputLabel__unableToScan2:
          'Alternatiivselt, kui teie autentimisrakendus toetab TOTP URI-sid, saate ka kopeerida täis-URI.',
      },
      removeResource: {
        messageLine1: 'Selle autentija koodid ei ole enam vajalikud sisselogimisel.',
        messageLine2: 'Teie konto ei pruugi olla nii turvaline. Kas olete kindel, et soovite jätkata?',
        successMessage: 'Kaheastmeline kinnitamine autentimisrakenduse kaudu on eemaldatud.',
        title: 'Eemalda kaheastmeline kinnitamine',
      },
      successMessage:
        'Kaheastmeline kinnitamine on nüüd lubatud. Sisselogimisel peate sisestama selle autentimisrakenduse genereeritud koodi lisasammuna.',
      title: 'Lisa autentimisrakendus',
      verifySubtitle: 'Sisestage autentimisrakenduse genereeritud kinnituskood',
      verifyTitle: 'Kinnituskood',
    },
    mobileButton__menu: 'Menüü',
    navbar: {
      account: 'Profiil',
      description: 'Haldage oma konto teavet.',
      security: 'Turvalisus',
      title: 'Konto',
    },
    passkeyScreen: {
      removeResource: {
        messageLine1: '{{name}} eemaldatakse sellest kontost.',
        title: 'Eemalda paroolivõti',
      },
      subtitle__rename: 'Saate paroolivõtme nime muuta, et see oleks lihtsam leida.',
      title__rename: 'Muuda paroolivõtme nime',
    },
    passwordPage: {
      checkboxInfoText__signOutOfOtherSessions:
        'Soovitatav on välja logida kõigilt teistelt seadmetelt, mis on võinud teie vana parooli kasutada.',
      readonly: 'Teie parooli ei saa praegu muuta, kuna saate sisse logida ainult ettevõtte ühenduse kaudu.',
      successMessage__set: 'Teie parool on seadistatud.',
      successMessage__signOutOfOtherSessions: 'Kõik teised seadmed on välja logitud.',
      successMessage__update: 'Teie parool on uuendatud.',
      title__set: 'Seadistage parool',
      title__update: 'Uuendage parooli',
    },
    phoneNumberPage: {
      infoText:
        'Sellele telefoninumbrile saadetakse tekstisõnum, milles on kinnituskood. Sõnumi ja andmete tasud võivad kehtida.',
      removeResource: {
        messageLine1: '{{identifier}} eemaldatakse sellest kontost.',
        messageLine2: 'Te ei saa enam selle telefoninumbriga sisselogimist.',
        successMessage: '{{phoneNumber}} on teie kontolt eemaldatud.',
        title: 'Eemalda telefoninumber',
      },
      successMessage: '{{identifier}} on teie kontole lisatud.',
      title: 'Lisa telefoninumber',
      verifySubtitle: 'Sisestage kinnituskood, mis saadeti {{identifier}}',
      verifyTitle: 'Kinnitage telefoninumber',
    },
    profilePage: {
      fileDropAreaHint: 'Soovitatav suurus 1:1, kuni 10MB.',
      imageFormDestructiveActionSubtitle: 'Eemalda',
      imageFormSubtitle: 'Laadi üles',
      imageFormTitle: 'Profiilipilt',
      readonly: 'Teie profiili teave on antud ettevõtte ühenduse kaudu ja seda ei saa muuta.',
      successMessage: 'Teie profiil on uuendatud.',
      title: 'Uuenda profiili',
    },
    start: {
      activeDevicesSection: {
        destructiveAction: 'Logi välja seadmel',
        title: 'Aktiivsed seadmed',
      },
      connectedAccountsSection: {
        actionLabel__connectionFailed: 'Ühenda uuesti',
        actionLabel__reauthorize: 'Autoriseeri nüüd',
        destructiveActionTitle: 'Eemalda',
        primaryButton: 'Ühenda konto',
        subtitle__disconnected: 'See konto on lahutatud.',
        subtitle__reauthorize:
          'Nõutavad õigused on ajakohastatud ja võite kogeda piiratud funktsionaalsust. Palun reautoriseerige see rakendus, et vältida probleeme',
        title: 'Ühendatud kontod',
      },
      dangerSection: {
        deleteAccountButton: 'Kustuta konto',
        title: 'Kustuta konto',
      },
      emailAddressesSection: {
        destructiveAction: 'Eemalda e-post',
        detailsAction__nonPrimary: 'Määra peamiseks',
        detailsAction__primary: 'Lõpeta kinnitamine',
        detailsAction__unverified: 'Kinnita',
        primaryButton: 'Lisa e-posti aadress',
        title: 'E-posti aadressid',
      },
      enterpriseAccountsSection: {
        title: 'Ettevõtte kontod',
      },
      headerTitle__account: 'Profiili üksikasjad',
      headerTitle__security: 'Turvalisus',
      mfaSection: {
        backupCodes: {
          actionLabel__regenerate: 'Regeneratsiooni',
          headerTitle: 'Varukoodid',
          subtitle__regenerate:
            'Saage uus komplekt turvalisi varukoode. Eelmised varukoodid kustutatakse ja neid ei saa enam kasutada.',
          title__regenerate: 'Regeneratsiooni varukoode',
        },
        phoneCode: {
          actionLabel__setDefault: 'Määra vaikimisi',
          destructiveActionLabel: 'Eemalda',
        },
        primaryButton: 'Lisa kaheastmeline kinnitamine',
        title: 'Kaheastmeline kinnitamine',
        totp: {
          destructiveActionTitle: 'Eemalda',
          headerTitle: 'Autentimisrakendus',
        },
      },
      passkeysSection: {
        menuAction__destructive: 'Eemalda',
        menuAction__rename: 'Nime muuda',
        title: 'Paroolivõtmed',
      },
      passwordSection: {
        primaryButton__setPassword: 'Seadista parool',
        primaryButton__updatePassword: 'Uuenda parooli',
        title: 'Parool',
      },
      phoneNumbersSection: {
        destructiveAction: 'Eemalda telefoninumber',
        detailsAction__nonPrimary: 'Määra peamiseks',
        detailsAction__primary: 'Lõpeta kinnitamine',
        detailsAction__unverified: 'Kinnita telefoninumber',
        primaryButton: 'Lisa telefoninumber',
        title: 'Telefoninumbrid',
      },
      profileSection: {
        primaryButton: 'Uuenda profiili',
        title: 'Profiil',
      },
      usernameSection: {
        primaryButton__setUsername: 'Määra kasutajanimi',
        primaryButton__updateUsername: 'Uuenda kasutajanime',
        title: 'Kasutajanimi',
      },
      web3WalletsSection: {
        destructiveAction: 'Eemalda rahakott',
        primaryButton: 'Ühenda rahakott',
        title: 'Web3 rahakotid',
      },
    },
    usernamePage: {
      successMessage: 'Teie kasutajanimi on uuendatud.',
      title__set: 'Määra kasutajanimi',
      title__update: 'Uuenda kasutajanime',
    },
    web3WalletPage: {
      removeResource: {
        messageLine1: '{{identifier}} eemaldatakse sellest kontost.',
        messageLine2: 'Te ei saa enam selle web3 rahakotiga sisselogimist.',
        successMessage: '{{web3Wallet}} on teie kontolt eemaldatud.',
        title: 'Eemalda web3 rahakott',
      },
      subtitle__availableWallets: 'Valige web3 rahakott, et oma kontoga ühendada.',
      subtitle__unavailableWallets: 'Saadaolevaid web3 rahakotte ei ole.',
      successMessage: 'Rahakott on teie kontole lisatud.',
      title: 'Lisa web3 rahakott',
      web3WalletButtonsBlockButton: '{{provider|titleize}}',
    },
  },
} as const;