import React from "react";
import {Helmet} from "react-helmet-async";

const TermsOfService = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5 pb-4">
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            Privacy policy
          </h1>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p><strong>Effective Date</strong>: 05.11.2024</p>

        <p>PuhkuseTark (puhkusetark.ee) is committed to protecting the privacy of its users. This Privacy Policy
          outlines how we collect, use, share, and protect your information when you use our platform for managing
          short-term rentals.</p>

        <h2>1. Information We Collect</h2>
        <ul>
          <li><strong>Personal Information:</strong> Names, email addresses, and phone numbers are collected for
            authentication and communication purposes.
          </li>
          <li><strong>Authentication Information:</strong> We use Google, Facebook, and other providers for user
            authentication.
          </li>
          <li><strong>Payment Information:</strong> Payment processing is handled securely by Stripe, which has its own
            privacy practices.
          </li>
          <li><strong>Location Data:</strong> Customer’s last known location is stored in the app's persistent storage.
            Rental locations are stored in our database as provided by users. We may also log approximate location based
            on search history.
          </li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <ul>
          <li><strong>To Provide Services:</strong> We use your information to enable user authentication, manage
            rentals, and provide other services.
          </li>
          <li><strong>Marketing:</strong> If you consent, we may use your email to send newsletters or updates. You can
            unsubscribe at any time.
          </li>
          <li><strong>Blog Subscriptions:</strong> Blog articles may be sent via email if you opt into a separate
            subscription.
          </li>
        </ul>

        <h2>3. Sharing Your Information</h2>
        <ul>
          <li><strong>Third-Party Providers:</strong> We share information with Google for authentication purposes only.
            No data is used for third-party marketing.
          </li>
          <li><strong>Stripe:</strong> Payments are processed via Stripe. We do not store payment information directly
            on our servers.
          </li>
        </ul>

        <h2>4. Data Storage and Security</h2>
        <p>We implement appropriate security measures to protect your personal information. However, no online platform
          is entirely secure. Please use secure passwords and protect your login credentials.</p>

        <h2>5. User Rights</h2>
        <ul>
          <li><strong>Access and Correction:</strong> You may access and correct your information within your account
            settings.
          </li>
          <li><strong>Deletion:</strong> You can request account deletion by contacting us.</li>
          <li><strong>Marketing Opt-Out:</strong> You can unsubscribe from marketing emails at any time.</li>
        </ul>

        <h2>6. Children’s Privacy</h2>
        <p>Users must be at least 12 years old to use PuhkuseTark.</p>

        <h2>7. Changes to this Policy</h2>
        <p>We may update this Privacy Policy as needed. Changes will be communicated via email or on our website.</p>

        <h2>8. Contact Us</h2>
        <p>If you have questions or concerns about this Privacy Policy, please contact us at info@puhkusetark.ee.</p>

      </div>
    );
  };


  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>PuhkuseTark | Privacy policy</title>
      </Helmet>
      {renderHeader()}

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      </div>
    </div>
  );
};

export default TermsOfService;
