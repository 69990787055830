import React from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import imagePng from "images/hero-right.png";
import SectionHero from "components/SectionHero/SectionHero";

function PlaceHome({placeName}) {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero
          title={"Place " + placeName}
          text={"Accompanying us, you have a trip full of experiences. With Chisfis,\n" +
            "            booking accommodation, resort villas, hotels"}
          imagePng={imagePng}
          className="pt-10 lg:pt-16 lg:pb-16" />
      </div>
    </div>
  );
}

export default PlaceHome;
